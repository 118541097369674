import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "../../styles/series-benefits.css"

function bottomCta(cta) {
    if(cta) {
      return <a href={cta.url} className="btn btn-small-arrow color-white" target={cta.target}>{cta.title}</a>
    }
  }

const SeriesBenefits = (props) => {
return (
    <section className="sec-series-benfits sec-content-benfits bg-black-true padding-top--vw block-vw--btm" id={"sec-" + props.index}>
    <header className="benfits-header">
    {props.content.sectionHeading &&
       <div className="d-md-flex relative">
         <div className="col-md-3 font-16">
           <div className="quote-line quote-line-lg color-white" data-sal="fade-in"></div>
           <div data-sal="slide-right" data-sal-delay="0">{props.content.sectionHeading}</div>
         </div>
       </div>
    }

      <figure>
      {props.content.headerImage &&
      <GatsbyImage
        image={props.content.headerImage.localFile.childImageSharp.gatsbyImageData}
        alt={props.content.headerImage.altText}
        // style={{width: '60%'}}
      />
      }
      </figure>
    </header>

    <div className="wrapper-lg margin-top--160">
      <div className="d-md-flex">
      <div className="col-md-9" data-sal="slide-up" >
        <div className="d-md-flex flex-wrap row-60">
          {props.content.contentGrid &&
            props.content.contentGrid.map((content) => (
            <div className="col-md-6 gap-60 benefit-wrap">
              <h3 className="font-34" dangerouslySetInnerHTML={{  __html: content.heading }}></h3>
              <div className="font-16 color-white-50 margin-top--8">{content.shortDescription}</div>
            </div>
          ))}
        </div>
        </div>
        {props.content.sideList &&
          <div class="col-md-2 offset-xl-1" data-sal="slide-up" data-sal-delay="150">
            {props.content.sideList.map((list) => (
                <div class="font-16 padding-tb-24 list-items">{list.listItem}</div>
            ))}
          </div>
        }
      </div>

      {props.content.bottomCtaImage &&
        <div class="benefits-bottom-cta d-md-flex align-items-center margin-top--160 bg-off-black justify-content-between ">
          <div class="col-md-2">
              <figure>
                  <GatsbyImage
                      image={props.content.bottomCtaImage.localFile.childImageSharp.gatsbyImageData}
                      alt={props.content.bottomCtaImage.altText}
                  />
              </figure>
          </div>
          <div class="col-md-5">
              <div className="font-16 color-white-50 margin-top--15-md">{props.content.ctaSmallText}</div>
              <div className="font-28 color-white margin-top--4">{props.content.ctaLargeText}</div>
          </div>
          <div class="col-md-2 offset-xl-1 ben-btn">
              {bottomCta(props.content.ctaLink)}
          </div>
        </div>

      }
    </div>


    </section>
)

}
export default SeriesBenefits
